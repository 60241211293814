import React from 'react';
import styled from 'styled-components';

import { ReactComponent as Bot } from '../assets/icons/bot-wave.svg';
import { ReactComponent as Top } from '../assets/icons/top-wave.svg';
import { ScContainer } from '../components/container/styled';
import { ScPage } from '../components/page/styled';
import { ScTop } from '../components/Top/styled';
import { MainLayout } from '../layouts/main-layout';
import { FaqSections } from '../sections/EmployeeFaq/FaqSections';
import { EmployeeFaqInfoSection } from '../sections/EmployeeFaq/InfoSection';
import {SEO} from "../components/Seo/Seo";

const gogetaGuideLink = 'https://www.gogeta.com/static/Gogeta%20Bike%20-%20Employer%20Guide-863b7f42733b75d4a915857046630eea.pdf';

const accordionsBike = [
	{
		title: 'What is the Gogeta Bike scheme?',
		columns: 1,
		text: [
			`<p>Gogeta Bike is a cycle to work scheme. Cycle to work was introduced by the UK Government to get more people cycling. It lets you pay for bikes and cycling clothing and accessories out of your gross salary, before tax and national insurance contributions, saving you money. And because you pay for the cost of your bike by salary sacrificing the cost out of your monthly salary, it also lets you spread the cost over the salary sacrifice period</p>`,
		],
	},
	{
		title: 'How much can I save?',
		columns: 2,
		text: [
			`<p>Cycle to work savings are typically between 24%-44% on standard retail prices, depending on your tax band. However, because Gogeta Bike has the lowest commission for retailers, they never pass on surcharges to you when using our scheme and you’ll get access to other deals (like sale bikes).</p>`,
			`<p>This means the savings you make are usually even greater, sometimes over 50%. To see your personalised savings, based on your salary and your bike cost, please check out <a href="/employee-cycling#savings-calculator" target="_blank">our savings calculator</a>.</p>`,
		],
	},
	{
		title: 'How do I sign up?',
		columns: 1,
		text: [
			`<p>Any PAYE employee can use Gogeta Bike as long as your employer signs up. It’s simple, quick and offering Gogeta Bike as a benefit to staff doesn’t cost them a penny. Ask your employer to sign up on our <a href="/employer-cycling/plans/" target="_blank">website</a> or download a guide to Gogeta Bike to send to them <a href="${gogetaGuideLink}" target="_blank">here</a>.</p>`,
		],
	},
	{
		title: 'What is the Gogeta Bike platform fee?',
		columns: 2,
		text: [
			`<p>Employees pay a small platform fee when they use a Gogeta Bike voucher. This is 6% of the total voucher cost but because it is added to the total salary sacrifice amount (and therefore you save the tax on the platform fee) the actual cost is between 3.2%-4.3% (depending on your tax band).</p>`,
			`<p>Remember, with Gogeta Bike there are no retailer surcharges. Unlike with legacy cycle to work schemes, where retailers have no choice but to pass on surcharges to you due to the high commissions they are charged for accepting these cycle to work vouchers, with Gogeta bike shops will never charge you a surcharge for accepting our vouchers. So we can confidently say that Gogeta Bike is the most generous cycle to work scheme for you as an employee.</p>`,
		],
	},
	{
		title: 'Can I use any retailer?',
		columns: 2,
		text: [
			`<p>You can use Gogeta Bike at any participating retailer (check our retailer list <a href="/employee-cycling#retailer-list">here</a>). If your preferred retailer isn’t signed up, get in touch and we’ll get them on board straight away. </p>`,
			`<p>We are the only cycle to work scheme endorsed by the Association of Cycle Traders, due to our low commissions and fair and transparent pricing, and we are always growing our retail base.</p>`,
		],
	},
	{
		title: 'Can I add personal funds to a voucher?',
		columns: 1,
		text: [
			`<p>We are afraid not as the Government guidance states that adding funds is not allowable. So please get an accurate quote before you apply.</p>`,
		],
	},
	{
		title: 'What can I buy with my Gogeta Bike voucher?',
		columns: 1,
		text: [
			`<p>You can get any adult bike, including electric bikes, plus a wide range of cycling equipment and clothing. You can select cycling equipment with a bike or if you already have a bike, you can choose to get equipment only, with all the same savings. You cannot get GPS trackers, scooters, bike racks for cars, cameras, turbo trainers or rollers, power meters or children's bikes. For a definitive list of what accessories are eligible, please read the HMRC guidance <a href="https://www.gov.uk/hmrc-internal-manuals/employment-income-manual/eim21664">here.</a></p>`,
		],
	},
	{
		title: 'Is there a limit to how much I can apply for a voucher for?',
		columns: 1,
		text: [
			`<p>Your employer will set the maximum scheme voucher amounts for Gogeta Bike applications. You’ll be notified of these when your employer signs up and when you apply.</p>`,
		],
	},
	{
		title: 'How long is the salary sacrifice period?',
		columns: 1,
		text: [
			`<p>Your employer will set the salary sacrifice period. It's usually 12 months, but some companies set periods of 18, 24 or even 30 months. You’ll be notified of these when your employer signs up and when you apply.</p>`,
		],
	},
	{
		title: 'What happens at the end of the salary sacrifice term?',
		columns: 2,
		text: [
			`<p>The bike is now yours for all intents and purposes. However, there is a quirk in the HMRC cycle to work guidance which states that if you were to take legal ownership of your bike and equipment immediately upon paying off the salary sacrifice amount, you would face a benefit in kind tax of 25% based on the value of the bike (see HMRC’s depreciation table which is in this <a href="https://www.gogeta.com/blog/need-to-know-hire-agreements-transfer-of-ownership/">article</a> below).</p><p>Therefore, in order to ensure you don’t have to pay benefit in kind tax and can take advantage of the full cycle to work savings, Gogeta Bike (as well as every other cycle to work scheme), enter you into an extended hire agreement whereby you hire the bike for the remaining period and at the end of that the value is negligible.</p>`,
			`<p> To transfer ownership we have to charge a token amount, we make this as low as possible and charge £1. We know it sounds a bit complicated but in reality it isn’t. At the end of the initial salary sacrifice term, the bike is yours in every real sense. It’s also worth remembering - some other cycle to work schemes charge as much as 10% as a transfer of ownership fee. As we have explained, we have to charge something, but this will never be more than £1.</p>`,
		],
	},
	{
		title: 'What happens if I leave my job during the initial salary sacrifice period?',
		columns: 1,
		text: [
			`<p>If you leave during the salary sacrifice period, as per the salary sacrifice agreement you will sign as part of your application, your employer will deduct the outstanding balance from your final net pay.</p>`,
		],
	},
	{
		title: 'What happens if I take maternity/paternity/adoption leave?',
		columns: 1,
		text: [
			`<p>Your Gogeta Nursery salary sacrifice agreement can be paused during maternity or paternity leave.</p>`,
		],
	},
	{
		title: 'How do I spend my Gogeta Bike flexi-voucher?',
		columns: 1,
		text: [
			`<p>When you apply, your employer will approve your application and then pay an invoice for the voucher amount. You’ll receive a Gogeta Bike flexi-voucher by email. When you’re ready to use your voucher, just present it to your chosen retailer and they will redeem the voucher amount.</p>`,
		],
	},
	{
		title: 'Do I have to spend my Gogeta Bike flexi-voucher in one go?',
		columns: 1,
		text: [
			`<p>No, your voucher is flexible so you can spend the balance over different transactions with different retailers. You can check the balance of your Gogeta Bike flexi-voucher in your portal (you’ll be sent a link to this when your application is approved).</p>`,
		],
	},
	{
		title: 'How long is my Gogeta Bike flexi-voucher valid for?',
		columns: 1,
		text: [
			`<p>It's valid for 12 months. We strongly encourage you to spend your voucher in that period. However if you don't, we can extend the expiry period.</p>`,
		],
	},
	{
		title:
			'If I don’t spend the full amount on my Gogeta Bike voucher after the initial salary sacrifice period, can I get a refund on the balance?',
		columns: 1,
		text: [
			`<p>No. As you have entered into a salary sacrifice agreement with your employer, you are committed to the amount you selected when you applied. Any amount you do not spend will still be repaid via your salary sacrifice, so it's important to select the correct amount and then to fully spend it.</p>`,
		],
	},
	{
		title: 'What happens if my bike is stolen?',
		columns: 1,
		text: [
			`<p>It is your responsibility to insure your bike should you wish to. If your bike is stolen during the salary sacrifice period the employee remains liable for any remaining payments. If the bike is insured and replaced then the agreement continues as normal.</p>`,
		],
	},
];

const accordionsNursery = [
	{
		title: 'Who is Gogeta?',
		columns: 1,
		text: [
			`<p>Gogeta is a salary sacrifice benefits provider. Our mission is to make your salary go further, by helping you make the most of your payslip. Paying for everyday essentials like nursery bills, groceries or even bikes out of your gross salary saves you money.</p>`,
		],
	},
	{
		title: 'What is Gogeta Nursery?',
		columns: 1,
		text: [
			`<p>Gogeta Nursery is a workplace nursery scheme that lets you pay for nursery bills out of your gross salary, before tax and national insurance contributions. This allows you to save up to 40% on your nursery bill (exact savings depend on your tax code, check out our savings calculator for a personalised saving estimate).</p>`,
		],
	},
	{
		title: 'How much can I save?',
		columns: 1,
		text: [
			`<p>You can save between 19% and 40%, depending on your tax code. Unlike Tax Free Childcare, which caps savings at £2000 per year, the savings with Gogeta Nursery are uncapped. Exact savings will depend on your salary and tax code, check out our savings calculator for a personalised saving estimate.</p>`,
		],
	},
	{
		title: 'How does my nursery benefit?',
		columns: 1,
		text: [
			`<p>Your nursery also benefits when you sign up to Gogeta. As part of the scheme requirements, your employer makes a financial contribution your nursery. This is in addition to your nursery fees and doesn't cost you anything.</p>`,
		],
	},
	{
		title: 'I use the Government ‘free’ hours, can I still use Gogeta Nursery?',
		columns: 1,
		text: [
			`<p>Yes. Any discount on nursery fees is automatically deducted by the nursery and you can use Gogeta Nursery (and benefit from the savings) on the remaining amount due after the funded hours have been deducted.</p>`,
		],
	},
	{
		title: 'I currently use Tax Free Childcare, how does this compare?',
		columns: 2,
		text: [
			`<p>In most circumstances, a parent with one or more children at nursery saves more by using Gogeta Nursery. Tax free childcare saves parents 20% - but the saving is capped at £500 every three months (£2000 a year).</p><p>Gogeta Nursery saves you between 19-40% (depending on your tax band) but there are no limitations on how much you can save. So the savings you can make are potentially much larger. You can see your precise savings if you start the application process via the link your HR team should have shared.</p>`,
			`<p>Additionally, unlike with Tax Free Childcare, with Gogeta Nursery your child’s nursery gets additional funding (on top of your fees) which is paid by your employer as part of HMRC compliance. This does not cost you anything and you can decide with your nursery what they spend it on (toys, equipment, music lessons etc). So Gogeta Nursery offers better savings and benefits both you and your nursery. We're pretty passionate about it!</p>`,
		],
	},
	{
		title: 'Can I use Gogeta Nursery and Tax Free Childcare?',
		columns: 1,
		text: [
			`<p>If you use Gogeta Nursery to pay your child’s nursery fees you won’t also be able to use Tax Free Childcare for that child, as the nursery fees are paid via salary sacrifice. However, if you wanted to continue using Tax Free Childcare for an older child's after-school club (for example) and pay a younger child's nursery fees with Gogeta that is no problem.</p>`,
		],
	},
	{
		title: 'Can I use the Gogeta Nursery scheme with my current nursery?',
		columns: 1,
		text: [
			`<p>Almost all nurseries accept Gogeta Nursery In fact they love it because of the additional funding they receive from the employers of every parent using the scheme. Once you apply, we’ll get in touch with your child’s nursery and get them signed up if they aren’t already.</p>`,
		],
	},
	{
		title: 'How do I sign up?',
		columns: 1,
		text: [
			`<p>To use Gogeta Nursery your employer needs to sign up. It’s simple, quick and doesn’t cost them a penny. Click the link on the employee page on our website to download a guide you can send them.</p>`,
		],
	},
];

const accordionsTechAndHome = [
  {
    title: 'What is the Gogeta Tech & Home scheme?',
    columns: 1,
    text: [
      `<p>The Gogeta Tech & Home scheme lets you save up to 8% on Tech & Home purchases from our retail partners John Lewis, Apple, Curry’s and Ikea. You can then spread the cost over 12 months, by paying via salary sacrifice and saving on National Insurance contributions.</p>`,
    ],
  },
  {
    title: 'How does it work?',
    columns: 1,
    text: [
      `<p>You’ll visit the application link shared by your employer and let us know how much you want to spend at each retailer. Please note, there will be two separate links, one for Tech and one for Home. The link for Tech will allow you to spend at John Lewis, Apple or Currys. The link for Home will allow you to spend at John Lewis or Ikea. Once your employer approves your application, you’ll get a gift card to spend at your retailer of choice. It’s that simple! You’ll then repay the cost over 12 months out of your gross pay (saving up to 8% in National Insurance contributions).</p>`,
    ],
  },
  {
    title: 'Where can I shop?',
    columns: 1,
    text: [
      `<p>You can buy your tech at John Lewis, Apple and Curry’s. You can buy your home products at John Lewis or Ikea.</p>`,
    ],
  },
  {
    title: 'What can I buy?',
    columns: 1,
    text: [
      `<p>You can buy any Tech equipment or items for your home, including laptops, phones, earphones, sound systems, TVs, fridges, washing machines, sofas and beds. The list is endless!</p>`,
    ],
  },
  {
    title: 'Are there any fees or hidden costs to using Gogeta Tech & Home?',
    columns: 1,
    text: [
      `<p>No. There are absolutely no costs or fees to use Gogeta Tech & Home. Simply sign up and save up to 8% on your Tech & Home purchases and spread the cost over 12 months. There is no catch, it really is that simple.</p>`,
    ],
  },
  {
    title: 'How are the savings made?',
    columns: 1,
    text: [
      `<p>When you apply for a Gogeta Tech & Home gift card, you then repay the amount over 12 months, out of your gross pay. This means you save on National Insurance contributions, which will be between 2-8% depending on your tax bracket.</p>`,
    ],
  },
  {
    title: 'How long will my gift card last for?',
    columns: 1,
    text: [
      `<p>Curry’s gift cards remain valid for 24 months from the last activation date. John Lewis gift cards are valid for 2 years, while Apple and Ikea gift cards have no expiry date.</p>`,
    ],
  },
  {
    title: 'Can I use my gift cards online and in-store?',
    columns: 1,
    text: [
      `<p>You can use your gift cards either online or in-store.</p>`,
    ],
  },
  {
    title: 'Do I have to spend my Tech & Home balance in one go? ',
    columns: 1,
    text: [
      `<p>No. After your application has been approved, you’ll be sent a gift card for your chosen amount for your chosen retailer. You can spend that like a normal gift card and draw down from the balance as you wish.</p>`,
    ],
  },
  {
    title: 'How do I check the balance of my gift cards?',
    columns: 1,
    text: [
      `<p>You can check the balance of your gift cards by visiting the retailer’s website.</p>`,
    ],
  },
  {
    title: 'What happens if I leave my job within 12 months of signing up to Gogeta Tech & Home?',
    columns: 1,
    text: [
      `<p>When you sign up to Gogeta Tech & Home, you enter a 12 month salary sacrifice agreement, whereby you repay the cost of your items out of your gross pay. If you leave your job within that time, your employer will recover any outstanding amount for your final month’s salary sacrifice from your final pay packet. If you have a remaining balance on your gift card, you’ll be able to use this as normal, even after you have left.</p>`,
    ],
  },
  {
    title: 'Can I cancel my Gogeta Tech & Home application?',
    columns: 1,
    text: [
      `<p>You can cancel your initial application, as long as this has not already been approved by your employer. If your application has been approved and you have already received your gift card, you will not be able to cancel.</p>`,
    ],
  },
];

const EmployeeFaq = () => {

	return (
		<MainLayout>
			<ScPage>
				<ScTopWrapper>
					<ScTop disableHeight>
						<Top />
						<ScContainerWrapper>
							<ScContainer>
								<FaqSections
									title={'Employee FAQs'}
									accordionBikes={accordionsBike}
									nurseriesNurseries={accordionsNursery}
                  accordionTechAndHome={accordionsTechAndHome}
								/>
							</ScContainer>
						</ScContainerWrapper>
						<Bot className={'bot-img'} />
						<EmployeeFaqInfoSection />
					</ScTop>
				</ScTopWrapper>
			</ScPage>
		</MainLayout>
	);
};

export const ScTopWrapper = styled.div`
	.info-section-wrapper {
		@media (min-width: 1220px) {
			margin: 80px auto 100px auto;
		}
	}
	svg:first-child {
		background: none;
		width: 130vw;
		height: auto;
		margin-left: -15vw;
	}
	.bot-img {
		background: none;
		width: 130vw;
		height: auto;
		margin-left: -15vw;
	}
`;

export const ScContainerWrapper = styled.div`
	background: ${({ theme }) => theme?.colors.white};
`;

export default EmployeeFaq;

export const Head = () => (
	<SEO
		title="Employee FAQ | Gogeta Salary Sacrifice Benefits Explained"
		description="Answers to your questions about Gogeta’s salary sacrifice schemes. Learn how you can save on childcare, cycling, and more with our simple, tax-saving benefits."
	/>
)
