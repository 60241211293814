import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from 'styled-components';

import pdfBike from '../../assets/guides/Gogeta Bike - Employer Guide.pdf';
import pdfNursery from '../../assets/guides/Gogeta Nursery - Employer Guide.pdf';
import pdfTech from '../../assets/guides/Gogeta Tech & Home - Employer Guide.pdf';
import { CustomButton } from '../../components/custom-button';
import { useMatchMedia } from '../../hooks/use-match-media';
import { download } from '../../utils/download';
import { InfoSection } from '../EmployeeCycling/InfoSection';
import { ReadyToStartSaving } from '../EmployeeFaq/ReadyToStartSaving';

export const EmployeeFaqInfoSection = () => {
	const theme = useTheme();
	const isDesktop = useMatchMedia(`(min-width: 1220px)`);

	return (
		<>
			<InfoSection
				title="Employer FAQs"
				text="Got more questions? We’ve got you covered."
				buttons={
					<Link to="/employer-faq/">
						<CustomButton
							hoverBackground={theme?.colors.strongWhite}
							hoverColor={theme?.colors.darkText}
						>
							For Employers
						</CustomButton>
					</Link>
				}
			/>
			<ReadyToStartSaving
				title={`Invite your ${isDesktop ? '<br />' : ''} employer`}
				text={`<p>Let your employer know you want to use Gogeta. Download our short guide to share with them or simply send them a link to this website. They can apply in minutes online.</p>`}
			>
				<CustomButton
					color={theme?.colors.darkText}
					background={theme?.colors.strongWhite}
					onClick={() => download(pdfBike as string)}
				>
					Employer guide to Gogeta Bike
				</CustomButton>
				<CustomButton
					color={theme?.colors.darkText}
					background={theme?.colors.strongWhite}
					onClick={() => download(pdfNursery as string)}
				>
					Employer guide to Gogeta Nursery
				</CustomButton>
				<CustomButton
					color={theme?.colors.darkText}
					background={theme?.colors.strongWhite}
					onClick={() => download(pdfTech as string)}
				>
					Employer guide to Gogeta Tech & Home
				</CustomButton>
			</ReadyToStartSaving>
		</>
	);
};
